<template>
    <header class="header">
        <div class="wrap">
            <nav class="nav">
                <a href="/">
                    <img class="header__logo" :src="require('@/assets/logo.svg')" alt="INTUIT CONNECT">
                </a>
            </nav>
        </div>
    </header>
    <main class="main">
        <section class="first-section">
            <div class="first-section__bg">
                <img src="" alt="">
            </div>
            <div class="wrap first-section__wrap">
                <h1 class="first-section__title title">
                    We prioritize affordability, <br>
                    user-friendliness, and reliability
                </h1>
                <img class="first-section__img" :src="require('@/assets/phone.png')" alt="phone">
                <a class="button button-base first-section__link" href="#contact">Enroll Today</a>
            </div>
        </section>
        <section class="map-section">
            <div class="wrap map-section__wrap">
                <div class="map-section__block">
                    <h2 class="map-section__title title">
                        Maximize Profit with Competitive Pricing
                    </h2>
                    <p class="map-section__description description">
                        Just $6/year for Asset Tracking and $15/year for Video Telematics.
                    </p>
                </div>
                <div class="map-section__block">
                    <h2 class="map-section__title title">
                        Effortless Simplicity
                    </h2>
                    <p class="map-section__description description">
                        Simplified tasks and valuable insights for easy asset tracking, history, and video analysis.
                    </p>
                </div>
                <div class="map-section__block">
                    <h2 class="map-section__title title">
                        Seamless Scalability
                    </h2>
                    <p class="map-section__description description">
                        Expand your business without migration hassles.
                    </p>
                </div>
            </div>
        </section>
        <section class="intuit-section">
            <div class="wrap intuit-section__wrap">
                <div class="intuit-section__content">
                    <div class="intuit-section__block">
                        <img class="intuit-section__img" :src="require('@/assets/logo-transparent.svg')" alt="INTUIT CONNECT">
                    </div>
                    <div class="intuit-section__block">
                        <h2 class="intuit-section__title title">
                            Full Smartfone Compatibility
                        </h2>
                        <p class="intuit-section__description description">
                            Fully Compatibility with both Apple and Android
                        </p>
                    </div>
                    <div class="intuit-section__block">
                        <h2 class="intuit-section__title title">
                            Effortless Custom Branding
                        </h2>
                        <p class="intuit-section__description description">
                            Boost your brand with private-label capability
                        </p>
                    </div>
                    <div class="intuit-section__block">
                        <h2 class="intuit-section__title title">
                            Proudly Developed and Hosted
                            in the USA
                        </h2>
                        <p class="intuit-section__description description">
                            Rely on USA-based quality, security, and reliability
                        </p>
                    </div>
                    <div class="intuit-section__block">
                        <h2 class="intuit-section__title title">
                            E-commerce Integration
                        </h2>
                    </div>
                    <div class="intuit-section__block">
                        <h2 class="intuit-section__title title">
                            Dedicated US Support Team
                        </h2>
                    </div>
                </div>
                <div class="intuit-section__img-container">
                    <img class="intuit-section__phone" :src="require('@/assets/phone-2.png')" alt="phone">
                </div>
                <!--            <div class="intuit-section__command">-->
                <!--                <a class="button button-base" href="/">-->
                <!--                    Enroll Today-->
                <!--                </a>-->
                <!--                <p class="description important">-->
                <!--                    And Receive a  Complimentary Private Label with Five Video Licenses!-->
                <!--                </p>-->
                <!--            </div>-->
            </div>
        </section>
        <section class="contact-section" id="contact">
            <div class="wrap">
                <div class="contact">
                    <div class="contact__header">
                        <h2 class="contact__title title">
                            Enroll Today
                        </h2>
                        <p class="contact__description description">
                            and Receive a  Complimentary Private Label with Five Video Licenses!
                        </p>
                    </div>
                    <form class="contact__form" id="contactForm" name="contact_form" @submit.prevent="sendEmail">
                        <laber class="contact__label" v-for="(item, i) in form" :key="i">
                            <span class="contact__form-title">{{item.label}}</span>
                            <span class="contact__form-title " style="color: red" v-if="item.required">*</span>
                            <span class="contact__form-title"> :</span>
                            <input class="contact__form-input" v-model="params[item.id]" :type="item.type" :required="item.required" size="40">
                        </laber>
                        <div class="contact__button">
                            <button
                                type="submit"
                                class="button button-contact"
                                :disabled="!inProgress"
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    </main>
    <footer class="footer">
        <div class="wrap footer__wrap">
            <a href="/">
                <img class="footer__img" :src="require('@/assets/logo.svg')" alt="INTUIT CONNECT">
            </a>
        </div>
    </footer>
</template>

<script>
import axios from 'axios';
import { useToast } from "vue-toastification";
// @ is an alias to /src
const def_params = {
    name: '',
    phone: '',
    email: '',
    company: '',
    comment: '',
};
export default {
    name: 'HomeView',
    components: {},
    data() {
        return {
            toast: useToast(),
            inProgress: true,
            form: [
                {
                    id: 'name',
                    label: 'Name',
                    type: 'text',
                    required: true,
                },
                {
                    id: 'company',
                    label: 'Company Name',
                    type: 'text',
                    required: false,
                },
                {
                    id: 'phone',
                    label: 'Phone number',
                    type: 'tel',
                    required: false,
                },
                {
                    id: 'email',
                    label: 'E-mail',
                    type: 'email',
                    required: true,
                },
                {
                    id: 'comment',
                    label: 'Comment',
                    type: 'text',
                    required: false,
                    size: 200,
                },
            ],
            params: {...def_params}
        }
    },
    methods:{
        async sendEmail() {
            this.inProgress = false
            // (optional) Wait until recaptcha has been loaded.
            await this.$recaptchaLoaded()
            // Execute reCAPTCHA with action "login".
            const token = await this.$recaptcha('sendEmail')

            // Do stuff with the received token.
            // console.log('recaptcha', token)

            axios({
                method: 'post',
                url: "/email.php",
                data: {...this.params, token},
            })
                .then((resp) => {
                    // console.log('good', resp)
                    if(resp.data.error){
                        console.error(resp.data.message)
                        this.toast.error(resp.data.message)

                        //'<div class="alert alert-danger"> Error! The security token has expired or you are a bot.</div>'
                    } else {
                        console.log(resp.data.message)
                        this.toast.success(resp.data.message)
                        // '<p class="alert alert-success">Thank You! Your message has been successfully sent.</p>';
                        this.params = {...def_params}
                    }
                    this.inProgress = true
                    this.formSend = true
                })
                .catch((error) => {
                    console.warn(error)
                    //if(error.response.status == 403)
                    /*            //if(error.response.status == 500)*/
                    {
                        let message = "Something went wrong, your message could not be sent."
                        this.toast.error(message)
                        // 500:
                        // <p className="alert alert-warning">Something went wrong, your message could not be sent.</p>
                        // 403:
                        // <p className="alert-warning">There was a problem with your submission, please try again.</p>
                    }
                    this.inProgress = true
                })
            // return false;
        },
    }
}
</script>
