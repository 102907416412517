import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { VueReCaptcha } from 'vue-recaptcha-v3'
const app = createApp(App)
app.use(store)
app.use(router)

app.use(VueReCaptcha, {
    siteKey: '6Le5-HcjAAAAAEPmmmk7otSMI8lJS7GTaMyvKkYZ',
    loaderOptions: {
        useRecaptchaNet: true,
        autoHideBadge: false,
        explicitRenderParameters: {
            badge: "bottomleft",
        }
    }
})

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
app.use(Toast, {
    "optons": {
        "transition": "Vue-Toastification__bounce",
        "maxToasts": 20,
        "newestOnTop": true,

        "position": "top-right",
        "timeout": 5000,
        "closeOnClick": true,
        "pauseOnFocusLoss": true,
        "pauseOnHover": true,
        "draggable": true,
        "draggablePercent": 0.6,
        "showCloseButtonOnHover": false,
        "hideProgressBar": true,
        "closeButton": "button",
        "icon": true,
        "rtl": false
    }
});

app.mount('#app')
